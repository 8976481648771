// https://greensock.com/st-demos/

// https://codepen.io/GreenSock/pen/OJyPmgX

gsap.registerPlugin(ScrollTrigger);









// Parallax header
// https://codepen.io/GreenSock/pen/OJyPmgX

var $parallax = document.getElementsByClassName('parallax');
if($parallax.length > 0){

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: "#hero",
      start: "top top",
      end: "bottom top",
      scrub: true
    }
  });

  gsap.utils.toArray(".parallax").forEach(layer => {
    const depth = layer.dataset.depth;
    const movement = -(layer.offsetHeight * depth)
    tl.to(layer, {y: movement, ease: "none"}, 0)
  });

}




ScrollTrigger.defaults({
  toggleActions: "restart pause resume pause"
});

//const w = section.querySelector('.wrapper');
//const [x, xEnd] = (index % 2) ? ['100%', (w.scrollWidth - section.offsetWidth) * -1] : [w.scrollWidth * -1, 0];

var $a2 = document.getElementsByClassName('a2');

/*
if($a2.length > 0){
    
    gsap.from(".a2", {
      scrollTrigger: {
        trigger: ".a1",
        scrub: true,
        //pin: true, // pin the trigger element while active
        start: "top bottom",
        end: "center top"
      },
      x: -500,
      //repeat: -1,
      //scaleX: 0,
      transformOrigin: "top left", 
      ease: "none",
      //opacity: 0.3,
      modifiers: {
        //x: x => windowWrap(parseFloat(x)) + "px"
         x: gsap.utils.unitize(x => parseFloat(x) % 500) //force x value to be between 0 and 500 using modulus
      },
    });

}
*/


if($a2.length > 0){
    
    gsap.from(".a2", {
      scrollTrigger: {
        trigger: ".a1",
        scrub: true,
        //pin: true, // pin the trigger element while active
        start: "top bottom",
        end: "center center"
      },
      x: "-90%", 
      transformOrigin: "top left", 
      //duration: 3
    });

}




/*
// Staggered reveals with ScrollTrigger batchCallbacks()
// usage:
batch(".elementor-widget-container", {
  interval: 0.1, // time window (in seconds) for batching to occur. The first callback that occurs (of its type) will start the timer, and when it elapses, any other similar callbacks for other targets will be batched into an array and fed to the callback. Default is 0.1
  batchMax: 3,   // maximum batch size (targets)
  onEnter: batch => gsap.to(batch, {autoAlpha: 1, stagger: 0.15, overwrite: true}),
  onLeave: batch => gsap.set(batch, {autoAlpha: 0, overwrite: true}),
  onEnterBack: batch => gsap.to(batch, {autoAlpha: 1, stagger: 0.15, overwrite: true}),
  onLeaveBack: batch => gsap.set(batch, {autoAlpha: 0, overwrite: true})
  // you can also define things like start, end, etc.
});


// the magical helper function (no longer necessary in GSAP 3.3.1 because it was added as ScrollTrigger.batch())...
function batch(targets, vars) {
  let varsCopy = {},
      interval = vars.interval || 0.1,
      proxyCallback = (type, callback) => {
        let batch = [],
            delay = gsap.delayedCall(interval, () => {callback(batch); batch.length = 0;}).pause();
        return self => {
          batch.length || delay.restart(true);
          batch.push(self.trigger);
          vars.batchMax && vars.batchMax <= batch.length && delay.progress(1);
        };
      },
      p;
  for (p in vars) {
    varsCopy[p] = (~p.indexOf("Enter") || ~p.indexOf("Leave")) ? proxyCallback(p, vars[p]) : vars[p];
  }
  gsap.utils.toArray(targets).forEach(target => {
    let config = {};
    for (p in varsCopy) {
      config[p] = varsCopy[p];
    }
    config.trigger = target;
    ScrollTrigger.create(config);
  });
}
*/





